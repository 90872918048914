/**
* Page header styles
*/
.pagehead {
  background: $pagehead-background-color;
  border-bottom: $pagehead-bottom-border;

  &.with-timeline {
    border-bottom: none;
  }

  &__wrap {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  app-kpi {
    flex: 1 1 100%;
  }

  app-breadcrumb {
    flex: 1 1 100%;
  }

  &__title {
    flex: 1 1 auto;

    h1 {
      color: $pagehead-title-text-color;
      font-size: 20px;
      line-height: 1.5;
      margin: 6px 0;
    }
  }

  // &__search {
  // }

  &__tabs-block {
    margin-bottom: -10px;
    margin-top: 10px;
    order: 999;
  }

  &__tabs-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__tab {
    display: inline-block;
    font-size: 12px;
    cursor: pointer;

    button {
      display: block;
      transition: color 200ms ease, background 200ms ease;
      background: $pagehead-tab-background-color;
      color: $pagehead-tab-text-color;
      padding: 5px 10px;
      border-radius: 0;
      border: 0;
      text-transform: capitalize;

      &:hover {
        color: $pagehead-tab-text-color-hover;
        background: $pagehead-button-background-color-hover;
      }
    }

    &.active button {
      background: $pagehead-tab-background-color-active !important;
      color: $pagehead-tab-text-color-active !important;
    }

    + .pagehead__tab {
      margin-left: 2px;
    }
  }

  &__buttons-block {
    flex: 1 1 100%;
    text-align: right;
    position: relative;
  }

  &__button {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: $pagehead-button-text-color;
    height: 20px;
    padding: 5px 10px;
    border: 0;
    cursor: pointer;
    background-color: $pagehead-button-background-color;
    background-repeat: no-repeat;
    background-position: center;
    outline: 0 !important;
    transition: background 200ms ease;
    box-sizing: content-box;
    min-width: 50px;
    font-size: 14px;

    + .pagehead__button,
    + .pagehead__buttons-dropdown-wrap {
      margin-left: 2px;
    }

    &:hover {
      background-color: $pagehead-button-background-color-hover;
    }

    &:active {
      background-color: $pagehead-button-background-color-active;
    }

    &--new,
    &--update,
    &--send,
    &--download,
    &--consolidate-all {
      padding-left: 22px;

      &:before {
        font: normal normal normal 14px/1 FontAwesome;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 8.5px;
        left: 5px;
      }
    }

    &--import {
      color: $pagehead-button-text-color; 
      margin-right: 5px;
    
      &:hover {
        background-color: $pagehead-button-background-color-hover;
      }
    
      &:active {
        background-color: $pagehead-button-background-color-active;
      }
    
      &:before {
        content: "\f093"; 
        font: normal normal normal 14px/1 FontAwesome;
        width: 15px;
        height: 15px;
      }
    }
    

    &--new:before {
      content: "\f067";
    }

    &--update:before {
      content: "\f021";
    }

    &--send:before {
      content: "\f1d8";
    }

    &--download:before {
      content: "\f019";
    }

    &--consolidate-all:before {
      content: "\f0c5";
    }
  }

  &__buttons-dropdown-wrap {
    display: inline-block;
    position: relative;

    &:hover {
      .pagehead__buttons-dropdown {
        display: block;
      }
    }
  }

  &__buttons-dropdown {
    position: absolute;
    padding-top: 2px;
    right: 0;
    display: none;
    z-index: 9;

    .pagehead__button {
      width: calc(100% - 20px);

      + .pagehead__button {
        margin-left: 0;
        margin-top: 2px;
      }
    }
  }
}

@media (min-width: 420px) {
  .pagehead {
    &__title {
      flex: 1 1 auto;
    }

    &__search {
      flex: 0 1 260px;
    }

    &__tabs-block {
      flex: 1 0 100%;
    }

    &__tabs-block.extra {
      flex: 1 0 101%;
      width: fit-content;
    }
  }
}

@media (min-width: 768px) {
  .pagehead {
    &__title {
      h1 {
        margin: 0;
      }
    }
    &__buttons-block {
      flex: 1 1 auto;
    }
  }
}

// document details navigation menu

.pagehead__title-clickable.nav-menu-button {
  transition: color 200ms ease, background 200ms ease;
  background: $pagehead-tab-background-color;
  color: $pagehead-tab-text-color;
  padding: 5px 10px;
  border-radius: 0;
  border: 0;
  text-transform: capitalize;
  margin-right: 2px;
  margin-top: 2px;

  &:hover {
    color: $pagehead-tab-text-color-hover;
    background: $pagehead-button-background-color-hover;
  }

  &.active {
    background: $pagehead-tab-background-color-active !important;
    color: $pagehead-tab-text-color-active !important;
  }
}

/* Export button styles */
.export_button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: $pagehead-button-text-color;
  height: 20px;
  padding: 5px 10px;
  border: 0;
  cursor: pointer;
  background-color: $pagehead-button-background-color;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0 !important;
  transition: background 200ms ease;
  box-sizing: content-box;
  min-width: 50px;
  font-size: 14px;

  + .pagehead__button,
  + .pagehead__buttons-dropdown-wrap {
    margin-left: 2px;
  }

  &:hover {
    background-color: $pagehead-button-background-color-hover;
  }

  &:active {
    background-color: $pagehead-button-background-color-active;
  }

  &--icon {
    padding-left: 22px;

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 8.5px;
      left: 5px;
    }
  }

  &--new:before {
    content: "\f067";
  }

  &--update:before {
    content: "\f021";
  }

  &--send:before {
    content: "\f1d8";
  }

  &--download:before {
    content: "\f019";
  }

  &--consolidate-all:before {
    content: "\f0c5";
  }

  &:disabled,
  &.disabled {
    color: #ccc;
    background-color: $button-background-color-disabled;
    cursor: not-allowed;

    &:hover {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }
}
