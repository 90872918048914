/**
* Reviewers pages and components
*/

.reviewers {
  &__head {
    padding: 10px 10px 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__head-wrap {
    display: flex;

    .reviewers__title {
      flex: 1 1 auto;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 1;
  }

  &__button {
    background: $filter-button-background-color;
    border: 1px solid $filter-button-color;
    color: $filter-button-color;
    transition: opacity 250ms ease;

    &:hover {
      opacity: 0.6;
    }

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      color: inherit;
    }

    &:disabled,
    &.disabled {
      cursor: no-drop;
    }

    &--filter {
      height: 25px;

      &:before {
        content: '\f0b0';
      }
    }

    &--edit {
      align-self: baseline;
      height: 20px;
      font-size: 15px;
      margin-left: 10px;
      border: none;

      &:before {
        content: '\f044';
      }
    }
  }

  &__button_filter {
    background: $filter-button-background-color;
    border: none;
    color: $filter-button-color;
    transition: opacity 250ms ease;

    &:hover {
      opacity: 0.6;
    }
  }

  &__filters {
    flex: 1 1 100%;
  }

}

.icon-filter {
  vertical-align: middle;
}

.fit-content{
  width: fit-content;
  margin: 0 0 10px 0;

}

.font-size {
  font-size: 13px;
}

.font-size-icon {
  font-size: 22px;
}

.btn-filters {
  width: fit-content;
  margin-top:8px;
  margin-right: 5px;
  font-size: 13px;
  height: 38px;
}
