/**
* Mixins styles file
* Please use this file only to write mixins
*/

/*
    Demo for this article:
    http://blustemy.io/tooltips-with-sass-mixins/
*/

@mixin tooltip(
  $tooltip-background-color: white,
  $tooltip-arrow-base: 2em,
  $tooltip-arrow-length: 1em,
  $tooltip-border-width: 0,
  $tooltip-border-color: transparent,
  $tooltip-transition-out-duration: 0.3s,
  $position: 'top' //Possible positions "top", "left"
) {
  // Sizing and positioning
  font-family: 'Notosans-Regular';
  box-sizing: border-box;
  position: absolute;
  @include tooltip-position($position, $tooltip-arrow-length);
  margin-bottom: $tooltip-arrow-length;
  z-index: 100;

  // Text and color properties.
  background-color: $tooltip-background-color;
  text-align: center;

  // Animated properties.
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear $tooltip-transition-out-duration,
    opacity $tooltip-transition-out-duration ease-in 0s,
    transform $tooltip-transition-out-duration ease-in 0s;

  // Disable events.
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    @include tooltip-triangle(
      $position,
      $tooltip-arrow-base,
      $tooltip-arrow-length,
      $tooltip-border-width,
      $tooltip-background-color
    );
  }

  // Specific styles when the tooltip has a border
  @if $tooltip-border-width {
    border: $tooltip-border-width solid $tooltip-border-color;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      @include tooltip-triangle-border(
        $position,
        $tooltip-arrow-length,
        $tooltip-arrow-base,
        $tooltip-border-width,
        $tooltip-border-color
      );
    }
  }
}

@mixin has-tooltip($tooltip-transition-in-duration: 0.3s) {
  position: relative;
  // Prevent the tooltip from being clipped by its container.
  overflow: visible;

  // Display the tooltip on hover and on focus.
  &:hover,
  &:focus {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0); // horizontally centered
      transition: visibility 0s linear 0s,
        opacity $tooltip-transition-in-duration ease-out 0s,
        transform $tooltip-transition-in-duration ease-out 0s;
    }
  }
}

@mixin tooltip-position($position: 'top', $tooltip-arrow-length) {
  @if $position == 'top' {
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 100%;
  } @else if $position == 'right' {
    transform: translate(0, -50%) !important;
    left: 100%;
  } @else if $position == 'left' {
    transform: translate(0, -50%) !important;
    right: 100%;
  } @else if $position == 'bottom' {
    transform: translate(-50%, 0) !important;
    left: 50%;
    top: 100%;
  }
}

@mixin tooltip-triangle(
  $position: 'top',
  $tooltip-arrow-base: 2em,
  $tooltip-arrow-length: 1em,
  $tooltip-border-width: 0,
  $tooltip-background-color: white
) {
  @if $position == 'top' {
    top: 100%;
    left: 50%;
    border-top: ($tooltip-arrow-length - $tooltip-border-width)
      solid
      $tooltip-background-color;
    border-left: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
    border-right: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
    // Center the triangle.
    margin-left: ($tooltip-border-width - $tooltip-arrow-base / 2);
  } @else if $position == 'right' {
    top: 40%;
    right: 100%;
    border-top: ($tooltip-arrow-length - $tooltip-border-width)
      solid
      transparent;
    border-left: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
    border-right: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      $tooltip-background-color;
    border-bottom: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
  } @else if $position == 'left' {
    top: 40%;
    left: 100%;
    border-top: ($tooltip-arrow-length - $tooltip-border-width)
      solid
      transparent;
    border-left: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      $tooltip-background-color;
    border-right: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
    border-bottom: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
  } @else if $position == 'bottom' {
    bottom: 100%;
    left: 50%;
    border-top: ($tooltip-arrow-length - $tooltip-border-width)
      solid
      transparent;
    border-left: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
    border-right: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      transparent;
    border-bottom: ($tooltip-arrow-base / 2 - $tooltip-border-width)
      solid
      $tooltip-background-color;
    margin-right: ($tooltip-border-width - $tooltip-arrow-base / 2);
  }
}

@mixin tooltip-triangle-border(
  $position: 'top',
  $tooltip-arrow-length: 1em,
  $tooltip-arrow-base: 1em,
  $tooltip-border-width: 0,
  $tooltip-border-color: transparent
) {
  @if $position == 'top' {
    top: 100%;
    left: 50%;
    border-top: (
        $tooltip-arrow-length - $tooltip-border-width + $tooltip-border-width
      )
      solid
      $tooltip-border-color;
    border-left: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-right: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-bottom: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    // Center the triangle.
    margin-left: ($tooltip-border-width - $tooltip-arrow-base / 2) +
      $tooltip-border-width;
  } @else if $position == 'right' {
    top: 40%;
    right: 100%;
    border-top: (
        $tooltip-arrow-base - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-left: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-right: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      $tooltip-border-color;
    border-bottom: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
  } @else if $position == 'left' {
    top: 40%;
    left: 100%;
    border-top: (
        $tooltip-arrow-base - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-left: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      $tooltip-border-color;
    border-right: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-bottom: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
  } @else if $position == 'bottom' {
    bottom: 100%;
    left: 50%;
    border-top: (
        $tooltip-arrow-base - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-left: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      $tooltip-border-color;
    border-right: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      transparent;
    border-bottom: (
        $tooltip-arrow-base / 2 - $tooltip-border-width + $tooltip-border-width
      )
      solid
      $tooltip-border-color;
  }
}
