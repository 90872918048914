/**
* Pagination component styles
*/

.custom-pagination {
  position: relative;
  color: $pagination-text-color;
  
  .ngx-pagination {
    margin-bottom: 0 !important;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
    font-size: 14px;

    a {
      color: $pagination-link-color;
      background-color:white;
      color: #0047bb;
      border: 1px solid #e0e0e0; /* Este es el borde gris sutil */
      margin-left: -1px;

      &:hover {
        background-color: rgb(227, 227, 227);
        text-decoration: none;
        color:#0047bb;
        font-weight: bold; // Aplica negrita al texto cuando se pasa el ratón
      }
    }
    .current {
      margin-left: -1px;
      border: 1px solid #e0e0e0; /* Este es el borde gris sutil */
      background-color: #0047bb;
      text-decoration: none;
      color: white;
      font-weight: bold; // Aplica negrita al texto
    }
  }
  .show-for-sr {
    display: none;
  }

  .la-ball-circus {
    color: $spinner-color-light;
    right: 70px;
    top: 8px;
    position: absolute !important;
  }
}

/*@media only screen and (max-width: 1500px) {
  .custom-pagination {
    font-size: 8px;
  }
}*/
