$margin: 8px;
$internal-margin: 16px;
$section-margin: 24px;
$card-padding: 32px;
$font-size: 12px;
$title-font-size: 14px;

@mixin card {
  background-color: $document-dashboard-card-background-color;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
}

@mixin full-width-flex-item {
  min-width: 0 !important;
  width: 100%;
  flex: 1 1 auto;
}

@mixin md-fluid-font-size($font-size) {
  @include fluid-font-size($font-size, $font-size * 2.5, 1920px, 2560px);
}

.medic-dashboard {
  @include md-fluid-font-size($font-size);
  padding: $margin;

  &.modal-panel {
    padding: 0;
  }

  .md-header {
    @include md-fluid-font-size($title-font-size);
    border-bottom: solid 1px rgb(0 0 0 / 20%);
    margin-bottom: $internal-margin;
  }

  .md-data-box {
    margin-bottom: $section-margin;
    background-color: $document-dashboard-data-box-background-color;

    h2 {
      background-color: $document-dashboard-data-box-header-background-color;
      padding: $margin;
    }

    .md-data-box-main {
      display: flex;
      flex-flow: row wrap;
      padding: $internal-margin;
      margin-top: -$internal-margin;
      margin-left: -$internal-margin;

      &:not(:last-child) {
        padding-bottom: 0;
      }

      .md-data-box-card {
        flex: 1 0;
        min-width: 350px;
        margin-top: $internal-margin;
        margin-left: $internal-margin;
        padding: $internal-margin;
        position: relative;
        @include card();

        @media (max-width: 500px) {
          @include full-width-flex-item();
        }

        &.narrow {
          min-width: 180px;
        }

        &.extra-wide {
          min-width: 700px;

          @media (max-width: 1000px) {
            @include full-width-flex-item();
          }
        }

        &.full-width {
          width: 100%;
          flex: auto;
        }

        &.wide-padding {
          padding: $card-padding;
        }

        &.card-with-button {
          position: relative;
          padding-bottom: 80px;

          .card-button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: $document-dashboard-card-button-background-color;
            border: none;
            border-top: $document-dashboard-card-button-border-top;
            height: 48px;

            &:hover {
              background-color: $document-dashboard-card-button-hover-background-color;
            }
          }
        }

        @mixin bg-status-indicator-style {
          tr {
            background-color: $document-dashboard-bg-status-indicator-table-row-background-color;

            &:nth-child(even) {
              background-color: $document-dashboard-bg-status-indicator-even-table-row-background-color;
            }
          }

          .custom-pagination {
            background-color: $document-dashboard-bg-status-indicator-pagination-background-color;
          }
        }

        &.bg-status-indicator {
          &.red {
            background-color: $document-dashboard-bg-status-indicator-red;
            @include bg-status-indicator-style();
          }

          &.yellow,
          &.amber {
            background-color: $document-dashboard-bg-status-indicator-yellow;
            @include bg-status-indicator-style();
          }

          &.normal,
          &.green {
            background-color: $document-dashboard-bg-status-indicator-normal;
            @include bg-status-indicator-style();
          }
        }

        .md-data-box-card-main,
        .md-data-box-card-main-column {
          display: flex;
          flex-flow: row wrap;
          margin-top: -$internal-margin;
          margin-left: -$internal-margin;

          .md-data-box-card-item {
            flex: 1 0 auto;
            margin-top: $internal-margin;
            margin-left: $internal-margin;
          }
        }

        .md-data-box-card-main-column {
          flex-direction: column;
        }
      }
    }
  }

  h2 {
    @include md-fluid-font-size($title-font-size);
  }

  h3 {
    @include md-fluid-font-size(18px);
    font-weight: 700;
    margin-bottom: 24px;
  }

  .status-indicator {
    $status-indicator-size: 16px;

    display: inline-block;
    width: $status-indicator-size;
    height: $status-indicator-size;
    border-radius: 100%;
    vertical-align: -3px;

    margin-left: $margin / 2;

    &.left {
      margin-left: 0;
      margin-right: $margin / 2;
    }

    &.red {
      background-color: $document-dashboard-status-indicator-red;
    }

    &.yellow,
    &.amber {
      background-color: $document-dashboard-status-indicator-yellow;
    }

    &.normal,
    &.green {
      background-color: $document-dashboard-status-indicator-normal;
    }
  }

  .data-date,
  .data-total-number {
    position: absolute;
    right: $margin;
    top: $margin;
    @include md-fluid-font-size(11px);
  }

  .data-item-list {
    display: flex;
    flex-flow: row wrap;

    .data-item-list-item {
      flex: 1 0 auto;

      .data-item-list-item-label {
        font-weight: 700;
      }

      .data-item-list-item-value {
        margin-bottom: $internal-margin;

        .trend-indicator {
          @include md-fluid-font-size(16px);
        }

        .data-value {
          @include md-fluid-font-size(20px);
        }
      }
    }
  }

  .data-note {
    display: block;
    margin-bottom: $margin;
  }

  .md-row {
    display: flex;
    flex-flow: row wrap;

    .md-row-item {
      flex: 1 1 auto;
    }
  }

  .md-info-panel-row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    margin-top: -$internal-margin;
    margin-left: -$section-margin;

    .md-row-item {
      flex: 0 1 auto;

      margin-top: $internal-margin;
      margin-left: $section-margin;
    }
  }

  .md-item-set {
    margin: (-$margin) 0 0 (-$margin);

    .md-item-set-item {
      margin: $margin 0 0 $margin;
    }
  }

  .info-panel {
    border: solid 2px $document-dashboard-data-box-background-color;
    display: inline-block;

    h4 {
      @include md-fluid-font-size($font-size);
      padding: $margin;
      background-color: $document-dashboard-data-box-background-color;
      margin: -1px -1px 0 -1px;
    }

    .info-panel-main {
      padding: $margin;

      .info-panel-item:not(:last-child) {
        margin-bottom: $margin;
      }
    }

    &.hidden-item {
      display: none;

      &.visible {
        display: block;
      }
    }
  }

  .md-legend {
    border: solid 1px rgb(0 0 0 / 20%);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 $internal-margin ($internal-margin / 2) 0;
    margin-bottom: $internal-margin;

    .md-legend-item {
      flex: 0 0 auto;
      margin: ($internal-margin / 2) 0 0 $internal-margin;
    }
  }

  .column-list {
    display: flex;
    flex-flow: row wrap;
    margin-top: -$margin;

    .column-list-item {
      width: 50%;
      flex: auto;
      margin-top: $margin;

      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }

  .diagnosis-list {
    display: flex;
    flex-flow: row wrap;
    margin: (-$internal-margin) 0 0 (-$internal-margin);

    .diagnosis-list-item {
      flex: 0 1 auto;
      margin: $internal-margin 0 0 $internal-margin;
      padding: $margin;
      text-align: center;

      border: solid 1px rgb(0 0 0 / 20%);
      border-radius: 8px;

      display: flex;
      flex-flow: row nowrap;

      .diagnosis-flag {
        flex: 0 0;
      }

      .diagnosis-name {
        flex: 1 1;

        @include md-fluid-font-size(16px);
        font-weight: 700;
        font-style: italic;

        margin-left: $margin;
        margin-right: $margin;
      }
    }
  }

  .flag-indicator {
    border-left: solid 3px rgb(100 50 0);

    .flag-content {
      border: solid 1px rgb(0 0 0 / 20%);
      @include md-fluid-font-size(9px);
      color: rgb(255 60 60);
      padding: 0 6px;
      vertical-align: 3px;
      display: inline-block;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .more-info-button {
    $color: #ccc;

    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 16px;
    vertical-align: bottom;

    font-family: monospace;
    font-size: 10px;
    font-weight: 700;
    color: $color;
    border: solid 2px $color;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;

    &.active {
      $active-color: #666;

      color: $active-color;
      border-color: $active-color;
    }

    @media (min-width: 1921px) {
      width: 36px;
      height: 36px;
      font-size: 24px;
    }
  }

  .table {
    display: table;
    width: 100%;
    margin-bottom: $internal-margin;

    @include md-fluid-font-size($font-size);

    &-head {
      display: table-header-group;
    }

    &-head-cell {
      display: table-cell;
      text-align: center;
    }

    &-body {
      display: table-row-group;
    }

    &-row {
      display: table-row;
      background-color: $document-dashboard-table-row-background-color;

      &:nth-child(even) {
        background-color: $document-dashboard-even-table-row-background-color;
      }
    }

    &-label-cell,
    &-cell {
      display: table-cell;
    }

    &-head-cell,
    &-label-cell,
    &-cell {
      padding: $margin / 2;
    }

    &-cell {
      text-align: center;
    }

    &-head-cell,
    &-label-cell {
      font-weight: 600;
    }
  }

  table,
  th,
  td {
    border: none;
  }

  table {
    @include md-fluid-font-size($font-size);

    tr {
      background-color: $document-dashboard-table-row-background-color;

      &:nth-child(even) {
        background-color: $document-dashboard-even-table-row-background-color;
      }
    }

    th,
    td {
      padding: $margin / 2;
    }
  }

  .data-list {
    li {
      padding: $margin / 2;
      background-color: $document-dashboard-table-row-background-color;

      &:nth-child(even) {
        background-color: $document-dashboard-even-table-row-background-color;
      }
    }
  }

  .custom-pagination {
    position: initial;
    background-color: $document-dashboard-pagination-background-color;
    margin-top: $margin / 2;
    text-align: center;
    @include md-fluid-font-size($font-size);
  }

  .md-panel {
    display: flex;
    flex-flow: row nowrap;

    @media (max-width: 500px) {
      flex-flow: column nowrap;

      .md-panel-menu {
        flex: 1 1 auto !important;
      }
    }

    .md-panel-menu {
      flex: 0 0 auto;
      padding-top: $internal-margin / 2;
      padding-bottom: $internal-margin / 2;
      background-color: $document-dashboard-panel-menu-background-color;

      .menu-item {
        padding-top: $internal-margin / 2;
        padding-bottom: $internal-margin / 2;
        padding-left: $internal-margin;
        padding-right: $internal-margin;

        &.active {
          background-color: $document-dashboard-panel-menu-active-background-color;
        }

        &:not(.active):hover {
          background-color: $document-dashboard-panel-menu-hover-background-color;
          cursor: pointer;
        }
      }
    }

    .md-panel-content {
      flex: 1 1 auto;
      background-color: $document-dashboard-panel-content-background-color;
      padding: $internal-margin;
      overflow-x: auto;

      h2 {
        margin-bottom: $internal-margin !important;
      }
    }
  }
}

.modal__header.medic-dashboard-modal-header {
  margin-bottom: 0;
}
