/**
* Default variables file
*
* Use semantic names ($element-link-color: blue),
* instead of value-descriptive names ($blue: blue)
* Variables may change in the future and would
* become useless ($blue: green)
* If you want to know what color it's defined,
* install a plugin in your IDE
*
* To create a new theme, duplicate this file
* and name it whatever you want. To load it:
* styleManager.setStyle('theme', '/assets/themes/your-theme-name.css');
*/

/* LAYOUT */

/* Body */
$body-text-color: #070768;
$body-background-color: #f4f6f9;
$active-color: #0047bb;
$app-background-color: #fff;

/* Header */
$header-background-color: #fff;
$header-text-color: #070768;
$header-text-color-active: #0047bb;
$header-logo-image: '/assets/img/SR-Logo.png';
$header-nav-background-color: #0047bb;
$header-nav-text-color: #070768;

/* Footer */
$footer-background-color: #0047bb;
$footer-text-color: #aaaaaa;
$footer-nav-link-color: #ffe600;

/* Sidebar */
$sidebar-link-font-weight: inherit;
$sidebar-text-color: #f6f6fa;
$icon-projects: '/assets/img/icons/file-1-white.svg';
$icon-scan: '/assets/img/icons/file-2-white.svg';
$icon-datamodels: '/assets/img/icons/windows-2-white.svg';
$icon-validations: '/assets/img/icons/list-white.svg';
$icon-outputfiles: '/assets/img/icons/file-clip-white.svg';
$icon-dashboard: '/assets/img/icons/app-white.svg';
$icon-time-tracking: '/assets/img/icons/time-tracking-white.svg';
$icon-client-management: '/assets/img/icons/client-management-white.svg';
$icon-admin: '/assets/img/icons/settings-white.svg';
$icon-external: '/assets/img/icons/external-white.svg';

/* Expiration */
$expiration-min-color: #0047bb;
$expiration-s-color: #ff4949;

/* Scrollbars */
$scrollbar-track-color: #2e2e38;
$scrollbar-track-shadow-color: rgba(38, 38, 47, 1);
$scrollbar-thumb-color: #97979c;
$scrollbar-thumb-shadow-color: rgba(59, 59, 68, 1);

/* Loading screen */
$loading-logo-image: '/assets/img/SR-Logo.svg';

/* COMPONENTS */
 
/* Links */
$link-color: #5da8f4;
$link-color-hover: #336699;

/* Buttons */
$button-text-color: #fff;
$button-background-color: #0047bb;
$button-darkblue-text-color: #070768;
$button-darkblue-border-color: #070768;
$button-white-border-color: #fff;
$button-white-background-color: #fff;
$button-darkblue-border-color: #070768;
$button-background-color-disabled: #f5f5f5;

/* Material */
$mat-panel-background-color: #424242;
$mat-radio-outer-circle-color: #000;
/* Breadcrumb */
$breadcrumb-text-color: #fff;

/* KPIs */
$kpis-background-color: #2e2e38;
$kpis-text-color: #070768;
$kpis-text-color-docsnum: #ca3433;
$kpis-text-color-quality: #c981b2;
$kpis-text-color-accuracy: #7fd1d6;
$kpis-text-color-datapoints: #95cb89;
$kpis-text-color-nodata: #505050;
$kpis-text-color-status: #d8d2e0;
$kpis-loading-text-color: #f6f6fa;

/* Pagehead */
$pagehead-bottom-border: none;
$pagehead-background-color: #245fae;
$pagehead-title-text-color: #fff;
$pagehead-tab-background-color: #070768;
$pagehead-tab-text-color: #fff;
$pagehead-tab-background-color-active: rgba(0, 71, 187, 0.7);
$pagehead-tab-text-color-active: #fff;
$pagehead-tab-text-color-hover: #fff;

$pagehead-button-background-color: #070768; //Christos
$pagehead-button-text-color: #fff; //Christos
$pagehead-button-background-color-hover: rgba(0, 71, 187, 0.45); //Christos
$pagehead-button-background-color-active: rgba(0, 71, 187, 0.45); //Christos

/* Tables */
$table-border: none;
$table-background-color: rgba(0, 71, 187, 1);
$table-text-color: #070768;
$table-header-font-weight: inherit;
$table-striped-background-color: #fff;
$table-striped-background-color-odd: rgba(0, 71, 187, 0.7);
$table-sort-icon-color: #fff;
$table-sort-icon-color-hover: #070768;
$table-responsive-head-background-color: #f6f6fa;
$table-responsive-head-text-color: #2e2e38;
$table-selection-background-color: #f6f6fa;
$table-selection-text-color: #f6f6fa;
$table-progress-bar-color: rgba(0, 71, 187, 0.7);
$table-progress-background-color: rgba(0, 71, 187, 1);
$table-checkbox-color: #070768;
$table-checkbox-white-color: #fff;
$table-checkbox-disabled-color: #070768;
$table-checkbox-border-color: #070768;
$table-row-hover-color: rgba(0, 71, 187, 0.45);
$table-row-hover-outline-color: rgba(0, 71, 187, 0.45);
$table-row-hover-highlight-color: rgba(0, 71, 187, 0.45);

/* Forms and inputs*/
$input-placeholder-text-color: #666666;
$input-disabled-blue: rgba(0, 71, 187, 0.45);
$form-field-dark-blue-color: #070768;
$form-field-white-color: #000000;
$form-field-dark-blue-underline: #070768;
$form-field-white-underline: #000000;
$select-arrow-dark-blue-color: #070768;
$select-arrow-white-color: #000000;
$select-dark-blue-color: #070768;
$select-white-color: #000000;

/* Searchbox */
$searchbox-border-color-focus: #07b3f6;
$searchbox-placeholder-text-color: #f6f6fa;
$searchbox-background-color: rgba(0, 71, 187, 1);
$searchbox-text-color: #fff;
$searchbox-border-color: rgba(0, 71, 187, 0.7);

/* Project card (grid) */
$project-card-background-color: rgba(0, 71, 187, 1);
$project-card-text-color: #f6f6fa;
$project-card-title-color: #f6f6fa;
$project-card-border-color: rgba(0, 0, 0, 0.12);
$project-card-text-color-light: #f6f6fa;
$project-card-text-color-lighter: #f6f6fa;
$project-card-line-color: #f6f6fa;
$project-card-analysis-text-color: #2c973e;
$project-card-document-text-color: #b955b7;

/* Pagination */
$pagination-background-color: #0047bb;
$pagination-text-color: #070768;
$pagination-link-color: #f6f6fa;
$pagination-link-color-hover: #666666;
$pagination-current-background-color: rgb(87, 144, 236);
$pagination-current-text-color: #f6f6fa;

/* Modals */
$modal-loader-background-color: rgba(0, 71, 187, 1);
$modal-loader-text-color: #070768;
$modal-background-color: #fff;
$modal-text-color: #070768;
$modal-header-text-color: #fff;
$modal-header-background-color: rgba(0, 71, 187, 1);
$modal-warning-color: #ffe600;
$modal-prohibited-color: #d93131;
$icon-close-modal: '/assets/img/close-white-2.svg';

/* Spinner */
$spinner-color-light: rgba(0, 71, 187, 1);
$spinner-color-dark: #2e2e38;

/* Marks */
$mark-background-color: rgba(0, 71, 187, 1);

/* Charts */
$chart-title-color: #070768; //#f6f6fa;
$chart-axis-line-color: rgba(255, 255, 255, 0.4);
$chart-axis-text-color: #070768; //#f6f6fa;
$chart-tooltip-background-color: rgba(0, 71, 187, 1); //#2e2e38;
$chart-tooltip-text-color: #fff; //#f6f6fa;

/* Project labels */
$project-label-background-color: #fff;
$project-label-text-color: rgba(0, 71, 187, 1);

/* Filter labels */
$filter-label-background-color: rgba(0, 71, 187, 0.7); //#26262f;
$filter-label-text-color: #fff; //#070768;
$filter-label-title-text-color: #070768;
$filter-button-background-color: transparent;
$filter-button-color: #fff;

/* Validation filters */
// from light theme TODO change
$valifilter-button-background-color: #f1f0f0;
$valifilter-button-color: #000000;
$valifilter-button-border-color: #999999;
$valifilter-button-active-background-color: #ffffff;
$valifilter-button-active-border-color: #155cb4;

/* Page control */
$icon-list: '/assets/img/icons/list-0-white.svg';
$icon-grid: '/assets/img/icons/grid-white.svg';

/* Alerts */
$alert-box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.3);

/* Update children */
$updatechildren-head-background-color: #26262f;
$updatechildren-body-background-color: #2e2e38;
$updatechildren-background-color: #3b3b44;
$updatechildren-panel-color: #3b3b46;
$updatechildren-light-color: #f6f6fa;
$updatechildren-active-color: #ffe600;
$updatechildren-error-color: #da4e4e;
$accordion-background-color: #26262f;
$accordion-color: #ffffff;

/* Modal accordion */
$modal-accordion-background-color: rgba(0, 71, 187, 1); //#26262f;
$modal-accordion-color: #ffffff;
$modal-panel-color: #fff; //#3b3b46;

/* Other */
$component-background-color: #fff;
$tag-color: #868686;
$icon-color: #f6f6fa;
$tooltip-color: #1c1c23;
$logtrack-line-color: #f3f3f7;
$chip-color: rgba(0, 71, 187, 0); //#26262f;
$selection-viewer-active-color: #ffe600a1;

/* PAGES */

/* Project list page */
$projectlist-text-color: #fff;
$projectlist-button-background-color: rgba(0, 71, 187, 1);
$projectlist-button-text-color: #070768;
$projectlist-button-background-color-hover: rgba(0, 71, 187, 0.45);
$projectlist-button-background-color-active: rgba(0, 71, 187, 0.45);

/* Login page */
$login-title-color: #f6f6fa;
$login-text-color: #f6f6fa;
$login-placeholder-color: #f6f6fa;
$login-box-border-color: #9d9d9d;
$login-box-text-color: aliceblue;
$login-description-color: #999999;
$login-forgotpass-color: #999999;
$login-forgotpass-color-hover: #f6f6fa;
$login-background-image: '/assets/img/login/login_bg.jpg';

/* Document view page */
$docview-nav-background-color: #070768;
$docview-nav-border-color: #fff;
$docview-nav-text-color: #f6f6fa;
$docview-pagination-text-color: #2e2e38;
$docview-nav-text-color-hover: #afafaf;

/* Query page */
$query-text-color: #f6f6fa;
$query-background-image: '/assets/img/Brain_2.svg';

/* Datamodel validation form */
$dataval-text-color: #000000;
$dataval-active-color: #0047bb;
$dataval-error-color: #da4e4e;
$dataval-alert-color: #da4e4e;
$dataval-head-background-color: rgba(0, 71, 187, 0.7);
$dataval-body-background-color: rgba(0, 71, 187, 1);
$dataval-box-background-color: #fff;
$datamodel-list-row-select-color: #fff;
$datamodel-list-row-select-underline-color: #fff;

/* Documents */
$documents-background-color: rgba(0, 71, 187, 1);
$documents-color: #fff;
$documents-active-color: rgba(0, 71, 187, 0.7); //#fff;
$documents-button-background-color: rgba(0, 71, 187, 1);
$docs-header-background-color: rgba(0, 71, 187, 1);
$docs-header-color: #fff;
$docs-body-background-color: #fff;
$docs-controls-background-color: rgba(0, 71, 187, 0.7);
$docs-navpage-input-background-color: transparent;
$docs-controls-border-color: #fff;
$docs-controls-color: #f6f6fa;
$docs-navpage-color: rgba(0, 71, 187, 1);
$docinfo-background-color: transparent;
$docinfo-color: #ffffff;
$docinfo-panel-background-color: #fff;
$docinfo-text-color: #070768;
$docinfo-title-color: #fff;
$docchildren-background-color: #3b3b44;
$valoverride-newcomment-background-color: transparent;
$valoverride-newcomment-color: #ffffff;
$valdoc-header-box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3);
$valdoc-button-background-color: transparent;
$valdoc-button-color: #ffffff;
$valdoc-title-color: #fff;
$valoverride-comment-background-color: #474750;
$valoverride-comment-border-color: rgba(0, 0, 0, 0.3);
$valoverride-file-hover-color: rgba(0, 71, 187, 1);
$valcollaplist-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
$valcollaplist-body-background-color: rgba(0, 71, 187, 1);
$valcollaplist-detail-background-color: #fff;
$valcollaplist-body-color: #fff;
$valcollaplist-header-color: #fff;
$valcollaplist-summary-color: #070768;
$valcollaplist-row-background-color: rgba(0, 71, 187, 0.45);

/* Thumbnails */
$thumbnail-background-color: rgb(0 0 0 / 75%);
$thumbnail-hover-color: rgb(255 255 255 / 50%);
$thumbnail-active-color: rgba(0, 71, 187, 1);
$thumbnail-stub-background-color: rgb(0 0 0 / 50%);

/* Timeline */
$timeline-bar-color: #696969;
$timeline-success-color: #47b04d;
$timeline-success-hover-color: #348939;
$timeline-failure-color: #da4e4e;
$timeline-failure-hover-color: #943030;
$timeline-pending-color: #696969;
$timeline-pending-hover-color: #4a4a4a;
$timeline-warning-color: #fe9700;
$timeline-warning-hover-color: #f47b00;
$timeline-in-progress-color: #00bbd3;
$timeline-in-progress-hover-color: #0096a6;
$timeline-skipped-color: #696969;
$timeline-skipped-hover-color: #4a4a4a;
$timeline-disabled-color: #070768;
$timeline-color: #fff;

/* Document field list */
$docfieldlist-background-color: #070768;
$docfieldlist-group-background-color: rgba(0, 71, 187, 1);
$docfieldlist-row-background-color: rgba(0, 71, 187, 0.7);
$docfieldlist-input-background-color: transparent;
$docfieldlist-border-color: #f6f6fa;
$docfieldlist-icon-color: #f6f6fa;
$docfieldlist-color-highlight: #fff;
$docfieldlist-color-outline: #fff;
$docfieldlist-color-validated: #070768;
$docfieldlist-color-row-hover: inherit;
$docfieldlist-color-light: #fff;
$docfieldlist-success-color: #47b04d;
$docfieldlist-danger-color: #da4e4e;
$docfieldlist-item-modified: #07b3f6;
$docsfieldlist-color-editarea: #070768;

/* Document dashboard */
$document-dashboard-card-background-color: #ffffff;
$document-dashboard-data-box-background-color: #f1f0f0;
$document-dashboard-data-box-header-background-color: #87d3f2;
$document-dashboard-card-button-background-color: rgb(0 0 0 / 5%);
$document-dashboard-card-button-border-top: solid 1px rgb(0 0 0 / 30%);
$document-dashboard-card-button-hover-background-color: rgb(255 255 255 / 50%);
$document-dashboard-table-row-background-color: #f0f0f0;
$document-dashboard-even-table-row-background-color: #ffffff;
$document-dashboard-pagination-background-color: #ffffff;
$document-dashboard-bg-status-indicator-red: #ffdddd;
$document-dashboard-bg-status-indicator-yellow: #ffffdd;
$document-dashboard-bg-status-indicator-normal: #ddffdd;
$document-dashboard-bg-status-indicator-table-row-background-color: rgb(
  255 255 255 / 50%
);
$document-dashboard-bg-status-indicator-even-table-row-background-color: rgb(
  255 255 255 / 80%
);
$document-dashboard-bg-status-indicator-pagination-background-color: rgba(
  255 255 255 / 80%
);
$document-dashboard-status-indicator-red: #ee0000;
$document-dashboard-status-indicator-yellow: #ffca00;
$document-dashboard-status-indicator-normal: #00dd00;
$document-dashboard-panel-menu-background-color: #f1f0f0;
$document-dashboard-panel-menu-active-background-color: #cccccc;
$document-dashboard-panel-menu-hover-background-color: #dddddd;
$document-dashboard-panel-content-background-color: #ffffff;

/* STATUS */

/* Status */
$nostatus-color: #9c9c9c;
$nostatus-color-dark: #747480;
$success-color: #4bae4f;
$success-color-dark: #419145;
$partial-success-color: #d2d040;
$partial-success-color-dark: #8f9e20;
$info-color: #00bbd3;
$info-color-dark: #0096a6;
$warning-color: #fe9700;
$warning-color-dark: #f47b00;
$danger-color: #d65b5b;
$danger-color-dark: #d93131;

/* Tooltips */
$tooltip-with-border-background-color: #f6f6fa;
$tooltip-with-border-arrow-base: 1.5em;
$tooltip-with-border-arrow-length: 0.75em;
$tooltip-with-border-border-width: 0.05em;
$tooltip-with-border-border-color: #999;
$tooltip-with-border-transition-out-duration: 0.2s;

$tooltip-without-border-background-color: #3e3d3e;
$tooltip-border-color: #2e2e38;
$tooltip-without-border-arrow-base: 1em;
$tooltip-without-border-arrow-length: 0.5em;
$tooltip-without-border-transition-out-duration: 0.2s;

/*PBI's dashboards*/
$portfolio-overview-capture: '/assets/img/dashboard-captures/portfolio_overview.png';
$processing-analytics-capture: '/assets/img/dashboard-captures/processing_analytics.png';
$compliance-analytics-capture: '/assets/img/dashboard-captures/compliance_analytics.png';
$risks-dollar-impact-capture: '/assets/img/dashboard-captures/risks_dollar_impact.png';
$average-cost-serve-capture: '/assets/img/dashboard-captures/average_cost_to_serve.png';
$exception-trend-capture: '/assets/img/dashboard-captures/exception_trend_analysis.png';
$spend-analysis-by-vendor-capture: '/assets/img/dashboard-captures/spend_analysis_by_vendor.png';
$payment-performance: '/assets/img/dashboard-captures/payment_performance.png';
$demo2_uk2: '/assets/img/dashboard-captures/demo2_uk2.png';
$demo2_uk1: '/assets/img/dashboard-captures/demo2_uk1.png';
$provider: '/assets/img/dashboard-captures/provider.png';
/* Material theme variables */
@import '~@angular/material/theming';
@include mat-core();
// EY palette generated with http://mcg.mbitson.com/#!?eypalette=%23FFE600&themename=EYpalette
$EYpalette: (
  50: #0047bb,
  100: #0047bb,
  200: #0047bb,
  300: #0047bb,
  400: #0047bb,
  500: #0047bb,
  600: #0047bb,
  700: #0047bb,
  800: #0047bb,
  900: #0047bb,
  A100: #070768,
  A200: #070768,
  A400: #070768,
  A700: #070768,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
// EY white palette generated with http://mcg.mbitson.com
$EYwhitepalette: (
  50: #070768,
  100: #070768,
  200: #070768,
  300: #070768,
  400: #070768,
  500: #070768,
  600: #070768,
  700: #070768,
  800: #070768,
  900: #070768,
  A100: #070768,
  A200: #070768,
  A400: #070768,
  A700: #070768,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
// EY error palette generated with http://mcg.mbitson.com
$EYerrorpalette: (
  50: #fbeaea,
  100: #f4caca,
  200: #eda7a7,
  300: #e58383,
  400: #e06969,
  500: #da4e4e,
  600: #d64747,
  700: #d03d3d,
  800: #cb3535,
  900: #c22525,
  A100: #fffefe,
  A200: #ffcbcb,
  A400: #ff9898,
  A700: #ff7f7f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$EY-app-primary: mat-palette($EYpalette);
$EY-app-secondary: mat-palette($EYwhitepalette);
$EY-app-warn: mat-palette($EYerrorpalette);
$EY-app-theme: mat-dark-theme($EY-app-primary, $EY-app-secondary, $EY-app-warn);
@include angular-material-theme($EY-app-theme);

/* Import all styles with previus defined variables  */
@import '../index';
