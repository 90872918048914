/**
* Document view styles
* Styles used to handle splited document pages
* Please use this file only to style document view properties
*/

/*
* The general approach is to add '.docview__info--fixed' and '.docview__picture--fixed'
* when the user scroll beyond a certain position, which is determined by the page head's height.
*
* To add this behaviur, you can use a HostListener like:
*
* @HostListener('window:scroll', ['$event'])
* public onScroll(event) {
*   this.windowScrollTop = window.pageYOffset
* }
*
* public isDocumentRenderFixed() {
*   return this.windowScrollTop > this.distanceDocumentRenderToWindowTop;
* }
*
* And in the template:
*
* <div class="docview__info" [ngClass]="{'docview__info--fixed': isDocumentRenderFixed()}">
*   //...
* </div>
*/

.docview {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__wrap2 {
    flex-wrap: wrap;
  }

  /* left part, where information goes */
  &__info {
    flex: 1 1 100%;
    order: 2;
    margin-top: 10px;
    box-sizing: border-box;
    width: 40% !important;
    margin-bottom: 2rem;
  }

  /* right part, where document image goes */
  &__picture {
    width: 57% !important;
    order: 1;
    margin: 0 auto;
    margin-left: 30px;
    &-app {
      height: 95%;
      display: block;
    }
  }
}

@media (min-width: 1099px) {
  .docview {
    &__wrap {
      flex-wrap: nowrap;
    }

    &__info {
      flex: 0 0 50%;
      padding-right: 4px;
      order: 1;
      margin-top: 0;

      &--expanded {
        flex: 1 1 100%;
      }
      &--fixed {
        flex-grow: 0;
      }
      &--fullscreen {
        display: none;
      }
    }

    &__picture {
      flex: 0 0 50%;
      padding-left: 4px;
      max-height: calc(100vh - 145px);

      &--fixed {
        position: fixed;
        // z-index: 9;
        // width: calc(50% - 75px);
        right: 20px;
        top: 66px;
        max-width: 48vw;
        // max-height: calc(100vh - 145px);

      }
      &--fullscreen {
        position: fixed;
        z-index: 9;
        width: calc(85  vw - 109px) !important;
        right: 10px;
        height: calc(100vh - 172px);
        max-height: initial;
      }
    }
  }
}

@media (min-width: 1100px) {
  .docview {
    $fields-table-fixed-width: 390px;
    &__info {
      width: $fields-table-fixed-width;
      flex: none;
      &--expanded {
        flex: 1 1 100%;
      }
    }
    &__picture {
      flex: none;
      width: calc(100% - #{$fields-table-fixed-width});
      // &--fixed {
      //   width: calc(100vw - #{$fields-table-fixed-width} - 125px);
      // }
    }
  }
}

@media (min-width: 1270px) {
  .docview {
    $fields-table-fixed-width: 450px;
    &__info {
      width: $fields-table-fixed-width;
      flex: none;
      &--expanded {
        flex: 1 1 100%;
      }
    }
    &__picture {
      flex: none;
      width: calc(100% - #{$fields-table-fixed-width});
      // &--fixed {
      //   width: calc(100vw - #{$fields-table-fixed-width} - 125px);
      // }
    }
  }
}

@media (min-width: 1380px) {
  .docview {
    $fields-table-fixed-width: 550px;
    &__info {
      width: $fields-table-fixed-width;
      flex: none;
      &--expanded {
        flex: 1 1 100%;
      }
    }
    &__picture {
      flex: none;
      width: calc(100% - #{$fields-table-fixed-width});
      // &--fixed {
      //   width: calc(100vw - #{$fields-table-fixed-width} - 125px);
      // }
    }
  }
}

@media (min-width: 1500px) {
  .docview {
    $fields-table-fixed-width: 620px;
    &__info {
      width: $fields-table-fixed-width;
      flex: none;
      &--expanded {
        flex: 1 1 100%;
      }
    }
    &__create {
      width: 100%;
      flex: none;
      &--expanded {
        flex: 1 1 100%;
      }
    }
    &__picture {
      flex: none;
      width: calc(100% - #{$fields-table-fixed-width});
      // &--fixed {
      //   width: calc(100vw - #{$fields-table-fixed-width} - 125px);
      // }
    }
  }
}