/**
* Tooltip styles
*/

.has-tooltip {
  cursor: pointer;
  @include has-tooltip($tooltip-transition-in-duration: 0.3s);
}

.tooltip {
  min-width: 10em;
  display: inline;
  background: white;
  border: 1px solid lightgray;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  color: gray;
  margin: 10px;
  padding: 10px;
  max-width: 350px;
  width: 350px;
  white-space: pre-line; // Use to handle text with breaklines (HR)
}

.tooltip-material {
  background: #111114;
}

.tooltip-top {
  @include tooltip(
    $tooltip-background-color: $tooltip-without-border-background-color,
    $tooltip-arrow-base: $tooltip-without-border-arrow-base,
    $tooltip-arrow-length: $tooltip-without-border-arrow-length,
    $tooltip-transition-out-duration:
      $tooltip-without-border-transition-out-duration
  );
  color: #fff;
  background-color: $tooltip-without-border-background-color;
  text-align: center;
  border-radius: 10px;
  border-color: $tooltip-border-color;
  border-style: solid;
  border-width: 2px;
}

.tooltip-left {
  @include tooltip(
    $tooltip-background-color: $tooltip-without-border-background-color,
    $tooltip-arrow-base: $tooltip-without-border-arrow-base,
    $tooltip-arrow-length: $tooltip-without-border-arrow-length,
    $tooltip-transition-out-duration:
      $tooltip-without-border-transition-out-duration,
    $position: 'left'
  );
  color: #fff;
  background-color: $tooltip-without-border-background-color;
  text-align: center;
  border-radius: 10px;
  border-color: $tooltip-border-color;
  border-style: solid;
  border-width: 2px;
}

.tooltip-right {
  @include tooltip(
    $tooltip-background-color: $tooltip-without-border-background-color,
    $tooltip-arrow-base: $tooltip-without-border-arrow-base,
    $tooltip-arrow-length: $tooltip-without-border-arrow-length,
    $tooltip-transition-out-duration:
      $tooltip-without-border-transition-out-duration,
    $position: 'right'
  );
  color: #fff;
  background-color: $tooltip-without-border-background-color;
  text-align: center;
  border-radius: 10px;
  border-color: $tooltip-border-color;
  border-style: solid;
  border-width: 2px;
}

.tooltip-bottom {
  @include tooltip(
    $tooltip-background-color: $tooltip-without-border-background-color,
    $tooltip-arrow-base: $tooltip-without-border-arrow-base,
    $tooltip-arrow-length: $tooltip-without-border-arrow-length,
    $tooltip-transition-out-duration:
      $tooltip-without-border-transition-out-duration,
    $position: 'bottom'
  );
  color: #fff;
  background-color: $tooltip-without-border-background-color;
  text-align: center;
  border-radius: 10px;
  border-color: $tooltip-border-color;
  border-style: solid;
  border-width: 2px;
}
