/**
* Angular material icons
* Please use this file only to style material icons properties
* 
* CAUTION! THESE STYLES AFFECTS TO ALL ICONS!
* 
* Icons list: https://material.io/resources/icons/
*/

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material_icons.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.6;
  }
}

/* Icon inside regular button
<button mat-stroked-button>
  <mat-icon>delete</mat-icon>
  Your button text here
</button>
*/
.mat-button-wrapper .mat-icon {
  font-size: inherit;
  vertical-align: middle;
  display: inline-block;
  height: inherit;
  width: initial;
  margin: -2px 2px 0;
}
