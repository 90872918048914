.valifilters {
  &__buttons {
    &.medic-filter {
      .button {
        background-color: $valifilter-button-background-color;
        border: solid 1px $valifilter-button-border-color;
        color: $valifilter-button-color;

        &.active {
          background-color: $valifilter-button-active-background-color;
          border-color: $valifilter-button-active-border-color;
        }
      }
    }
  }
}
