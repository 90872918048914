/**
* Status label styles
* Please use this file only to style document status cells on tables
*/

/* Status cell */
.text-status {
  white-space: pre;

  &--warning {
    color: $warning-color;
    &:before {
      background-color: $warning-color;
    }
  }

  &--success {
    color: $success-color;
    &:before {
      background-color: $success-color;
    }
  }

  &--failure,
  &--extraction-failure,
  &--br-failure,
  &--preliminary-failure,
  &--catalog-failure {
    color: $danger-color;
    &:before {
      background-color: $danger-color;
    }
  }

  &--in-progress,
  &--extraction-in-progress,
  &--br-in-progress,
  &--preliminary-in-progress,
  &--catalog-in-progress {
    color: $info-color;
    &:before {
      background-color: $info-color;
    }
  }

  &--pending,
  &--extraction-pending,
  &--br-pending,
  &--preliminary-pending,
  &--catalog-pending {
    color: $nostatus-color;
    &:before {
      background-color: $nostatus-color;
    }
  }

  &--partial-success {
    color: $partial-success-color;
    &:before {
      background-color: $partial-success-color;
    }
  }
}

/* Status cell without circle */
.text-noball-status {
  border-radius: 5px;
  margin-left: 3px;
  padding-right: 5px;
  position: relative;

  &--warning {
    background-color: $warning-color;
    color: white;
    &:before {
      background-color: $warning-color;
    }
  }

  &--success {
    background-color: $success-color;
    color: white;
    &:before {
      background-color: $success-color;
    }
  }

  &--failure,
  &--extraction-failure,
  &--br-failure,
  &--preliminary-failure,
  &--catalog-failure {
    background-color: $danger-color;
    color: white;
    &:before {
      background-color: $danger-color;
    }
  }

  &--in-progress,
  &--extraction-in-progress,
  &--br-in-progress,
  &--preliminary-in-progress,
  &--catalog-in-progress {
    background-color: $info-color;
    color: white;
    &:before {
      background-color: $info-color;
    }
  }

  &--pending,
  &--extraction-pending,
  &--br-pending,
  &--preliminary-pending,
  &--catalog-pending {
    background-color: $nostatus-color;
    color: white;
    &:before {
      background-color: $nostatus-color;
    }
  }

  &--partial-success {
    background-color: $partial-success-color;
    color: white;
    &:before {
      background-color: $partial-success-color;
    }
  }
}

/* Status border */
.status-border {
  border-bottom: 1px solid;
  padding: 5px;
  margin-bottom: 10px;

  &--warning {
    border-color: $warning-color !important;
  }

  &--success {
    border-color: $success-color !important;
  }

  &--failure {
    border-color: $danger-color !important;
  }

  &--in-progress {
    border-color: $info-color !important;
  }

  &--pending {
    border-color: $nostatus-color !important;
  }

  &--partial-success {
    border-color: $partial-success-color !important;
  }
}
