/**
* Alert styles
* Please use this file only to style alerts popups
*/
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  max-width: 95%;
  border-radius: 2px;
  opacity: 1;
  -webkit-animation: alert_appear 500ms cubic-bezier(0, 0.57, 0.25, 1) forwards;
  animation: alert_appear 500ms cubic-bezier(0, 0.57, 0.25, 1) forwards;
  display: block;
  margin: 3px;
  padding: 6px 10px;
  font-size: 14px;
  box-shadow: $alert-box-shadow;
  &-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  &-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }
  &-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  &-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  &-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  &-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  &-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
  }
  &-dismissible {
    .close {
      padding: 5px 10px;
      line-height: 1;
      background: transparent;
      border: none;
      float: right;
      color: inherit;
      span {
        line-height: 12px;
        color: inherit;
        font-size: 22px;
      }
    }
  }
}

@keyframes alert_appear {
  0% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(10px);
  }
}
