/**
* Forms with angular material components
* Please use this file only to style material form properties
*
* CAUTION! THESE STYLES AFFECTS TO ALL INPUTS!
*
* Components list and documentation: https://v7.material.angular.io/components/categories
*/

/* General inputs */
.mat-form-field {
  font-size: 14px;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    display: none; // Hide input up & down buttons
  }
}

.mat-form-field-required-marker {
  display: none !important; // Hide asterik on required fields labels
}

.cdk-overlay-container {
  z-index: 1200; // Set highest zindex on select, datepicker and others overlay container
}

/* Buttons */
button.mat-button,
button.mat-flat-button,
button.mat-icon-button,
button.mat-stroked-button {
  border-radius: 1px;
}

/* Datepicker */
// .mat-calendar {
//   background: $mat-panel-background-color; // Set custom background color for selection popup
// }

.mat-calendar-content {
  table,
  thead,
  tr,
  th,
  td {
    border: none;
  }
}

/* Checkbox */
.mat-checkbox {
  font-family: inherit !important;
  cursor: default !important;

  > label {
    cursor: pointer; // Set cursor pointer to the label instead the wrapper
  }

  &-label {
    font-size: 14px;
  }
}

/* Select */
.mat-select {
  border-bottom: none !important; // Remove error line inside input
}
.mat-select-panel {
  background: $mat-panel-background-color; // Set custom background color for selection popup
  -ms-overflow-style: none; // Hide scrollbar for IE and Edge
  border-radius: 2px;
  margin-top: 10px; // Move panel vertically so label can be readed
  margin-left: 15px; // Move panel horizontally so it's aligned with the label
  &::-webkit-scrollbar {
    // Hide scrollbar
    display: none;
  }
}
.mat-optgroup-label,
.mat-option {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); // Separation lines between options
}

/* Tags */
.mat-autocomplete-panel {
  background: $mat-panel-background-color; // Set custom background color for selection popup
}

/* Textarea */
.mat-form-field {
  textarea.mat-input-element {
    overflow: hidden; // Hide textarea scroll bar
  }
}

/* Errors */
.ng-invalid:not(form):not(div):not(app-operation):not(.mat-input-element) {
  // border-bottom: none !important; // Hide errors bottom line
}

/* Material tags component */
.matchip {
  min-width: unset !important;
}
.mat-standard-chip.mat-chip-with-trailing-icon {
  padding: 12px !important;
  min-height: 20px !important;
}
.mat-standard-chip .mat-chip-remove.mat-icon {
  width: 17px;
  height: 17px;
}
.mat-chip.mat-standard-chip {
  background-color: $chip-color;
}

.mat-input-element:disabled {
  color: $input-disabled-blue;
}