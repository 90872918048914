/**
* Related documents table styles
*/
.valdocs {
  &__loading {
    padding: 20px;
  }

  &__header {
    background: $documents-background-color;
    display: flex;
    padding: 10px;
    box-shadow: $valdoc-header-box-shadow;
  }
  &__title {
    color: $valdoc-title-color;
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 24px;
  }
  &__buttons {
    flex: 0 1 auto;
  }
  &__button {
    background: $valdoc-button-background-color;
    border: 1px solid $valdoc-button-color;
    color: $valdoc-button-color;
    font-size: 12px;
    padding: 3px 6px;
    transition: opacity 250ms ease;

    &.is-disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
    }

    &--disabled {
      cursor: not-allowed !important;
      opacity: 0.5 !important;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
