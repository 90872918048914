/**
* Document validation collapsible list styles
*/

%status-item {
  flex: 0 0 auto;
  padding: 8px 12px;
  line-height: 12px;
  width: 120px;
}

.valcollaplist {
  margin: 0 0 40px;
  font-size: 12px;
  box-shadow: $valcollaplist-box-shadow;

  &__header {
    color: $valcollaplist-header-color;
    display: flex;
    background: #6c757d;
    padding: 8px 12px;
    line-height: 2.5;
  }
  &__title {
    flex: 1 1 auto;
  }
  &__collapse {
    flex: 0 0 20px;
    cursor: pointer;
    transition: color 250ms ease;
    &:hover {
      color: $documents-active-color;
    }
    &:before {
      content: '\f065';
      font: normal normal normal 14px/1 FontAwesome;
    }
    &.is-collapsed:before {
      content: '\f066';
    }
  }

  &__version {
    background:white;
    color: black;
    padding: 4px 12px 10px;
    font-size: 12px;
    line-height: 1.33;
    outline: 1px solid transparent;
    transition: outline 250ms ease;
    display: flex;
  }

  &__icons-wrap {
    display: flex;
    margin-right: 10px;
  }

  &__icons {
    flex: 0 0 20px;
    cursor: pointer;
    position: relative;
    transition: color 250ms ease;
    top: 6px;

    &:hover,
    &.selected {
      color: $documents-active-color;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        color: inherit;
      }
    }

    &.is-disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:before {
      font: normal normal normal 18px/1 FontAwesome;
      text-rendering: auto;
      display: block;
      margin: 7px 4px 10px 0;
      width: 14px;
    }

    &--rerun:before {
      content: '\f01e';
    }

    &--rerun {
      position: relative;
      top: 7px;
    }

    &--exclude:before {
      content: '\f058';
    }
    &--check:before {
      content: '\f00c';
    }
    &--cross:before {
      content: '\f00d';
    }

    &--ignore:before {
      content: '\f138';
    }

    &--exclude {
      margin-left: 15px;
    }

    &--plus:before {
      content: '\f0fe';
    }

    &--edit:before {
      content: '\f044';
    }
    &--trash:before {
      content: '\f1f8';
    }
    &--resend:before {
      content: '\f1d8';
    }
    &--send:before {
      content: '\f064';
    }
  }

  &__preconditions {
    padding: 20px 20px 0px 20px;

    &__title {
      margin-bottom: 2px;
    }
  }

  &__body {
    background: #999da0;
    color: $valcollaplist-body-color;

    &.is-collapsed {
      display: none;
    }

    .valcollaplist {
      &__row {
        display: flex;
        outline: 1px solid transparent;
        transition: outline 250ms ease;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        &:hover {
          outline-color: white;
        }
      }
      &__row__condition {
        display: flex;
        outline: 1px solid transparent;
        transition: outline 250ms ease;
      }
      &__item-name {
        flex: 1 1 auto;
        padding: 8px 12px;
        cursor: pointer;
        &:before {
          content: '';
          display: inline-block;
          border: 5px solid transparent;
          border-top: 6px solid $documents-color;
          margin-right: 4px;
          vertical-align: top;
          margin-top: 4px;
        }
        &.is-collapsed:before {
          border: 5px solid transparent;
          border-top-color: transparent;
          border-left: 7px solid $documents-color;
          margin-top: 2px;
        }
      }

      &__item-detail-name {
        flex: 1 1 auto;
        padding: 8px;
        cursor: pointer;
      }
      &__item-status {
        @extend %status-item;
        &--success:before,
        &--manual-success:before {
          background: $timeline-success-color;
        }
        &--failure:before,
        &--manual-failure:before {
          background: $timeline-failure-color;
        }
        &--text {
          flex: 0 0 65px;
        }
      }
    }

    ul {
      margin: 0;
      &.is-collapsed {
        display: none;
      }
    }

    ul .valcollaplist__item-name {
      padding-left: calc(15px * 1 + 12px);
    }

    ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 2 + 12px);
    }

    ul ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 3 + 12px);
    }

    ul ul ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 4 + 12px);
    }

    ul ul ul ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 5 + 12px);
    }
  }

  &__detail {
    background: $valcollaplist-detail-background-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: block;
    &--loading {
      padding: 20px;
    }
    &.is-collapsed {
      display: none;
    }

    .valcollaplist__version {
      padding-left: calc(15px * 2 + 12px);
    }
  }

  &__summary {
    display: flex;
    color: $valcollaplist-summary-color;
    margin-bottom: 2px;
    &--success,
    &--manual-success {
      .valcollaplist__summary-msg {
        color: #1d9f23;
      }
      color: #1d9f23;
    }
    &--skipped {
      .valcollaplist__summary-msg {
        color: $timeline-skipped-color;
      }
      color: $timeline-skipped-color;
    }
    &--failure,
    &--manual-failure {
      .valcollaplist__summary-msg {
        color: $timeline-failure-color;
      }
      color: $timeline-failure-color;
    }
    &--failure-not-applicable {
      .valcollaplist__summary-msg {
        color: $timeline-skipped-color;
      }
      color: $timeline-skipped-color;
    }
    .valcollaplist__summary-list {
      li,
      ul {
        list-style-type: disc;
      }
      padding-left: 20px;
    }
  }
  &__summary-msg {
    padding: 12px;
    flex: 1 1 auto;
    line-height: 1.5;
  }
  &__summary-controls {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__summary-control {
    flex: 1 1 auto;
    transition: color 250ms ease;
    cursor: pointer;
    &:hover {
      color: $documents-active-color;
    }
    &:before {
      font: normal normal normal 18px/1 FontAwesome;
      text-rendering: auto;
      margin: 14px 6px;
      display: block;
    }
    &--override:before {
      content: '\f1e2';
    }
    &--rerun:before {
      content: '\f01e';
    }
  }
  &__summary-list {
    padding-left: 20px;
    li {
      list-style-type: disc;
    }
  }

  &__summary-detail {
    &:hover,
    &--active {
      color: $documents-active-color;
      cursor: pointer;
    }
  }

  &__condition {
    display: flex;
  }
  &__condition-msg {
    padding: 8px 12px 8px 14px;
    flex: 1 1 auto;
    &:before {
      content: '\f111';
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      display: inline-block;
      font-size: 6px;
      vertical-align: middle;
      margin: -1px 4px 0 0;
    }
  }
  &__condition-status {
    @extend %status-item;
    &--success:before,
    &--manual-success:before {
      background: $timeline-success-color;
    }
    &--failure:before,
    &--manual-failure:before {
      background: $timeline-failure-color;
    }
  }

  &__controls {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  &__control-btn {
    font-size: 14px;
    line-height: 2 !important;
  }
  &__control-btn-cancel {
    margin-right: 10px;
  }

  &__cancel {
    flex: 0 0 20px;
    cursor: pointer;
    transition: color 250ms ease;
    &:hover {
      color: $documents-active-color;
    }
    &:before {
      content: '\f00d';
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      display: block;
      margin: 8px auto;
      width: 14px;
    }
  }

  &__override {
    flex: 1 1 100%;
  }
}
